import { AsyncPipe, JsonPipe } from '@angular/common'
import { Component, isDevMode, OnInit } from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { Router, RouterLink } from '@angular/router'
import { ParagraphDirective } from '@app-ui/directives'
import { NotificationBarComponent } from '@app/domains/ui/components/notification-bar/notification-bar.component'
import { ButtonDirective } from '@app/domains/ui/directives/button/button.directive'
import { HeadingDirective } from '@app/domains/ui/directives/heading/heading.directive'
import { DefaultLayoutComponent } from '@app/domains/ui/layouts/default/default.layout.component'
import { toBase64 } from '@app/lib/toBase64'
import { FaceMatchService } from '@app/services'
import { RfidScannerService } from '@app/services/rfid-scanner/rfid-scanner.service'
import { UnattendendCheckInService } from '@app/services/unattendend-check-in/unattendend-check-in.service'
import Bugsnag from '@bugsnag/js'
import axios from 'axios'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'app-welcome-page',
    standalone: true,
    imports: [
        HeadingDirective,
        NotificationBarComponent,
        DefaultLayoutComponent,
        MatIcon,
        ButtonDirective,
        RouterLink,
        AsyncPipe,
        JsonPipe,
        ParagraphDirective,
    ],
    templateUrl: './welcome-page.component.html',
    styleUrl: './welcome-page.component.scss',
})
export class WelcomePageComponent implements OnInit {

    constructor(
        private readonly unattendedCheckInService: UnattendendCheckInService,
        private readonly faceMatchService: FaceMatchService,
        private readonly rfidScannerService: RfidScannerService,
        private readonly router: Router,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        try {
            await axios.get('http://172.16.1.2/state.xml?relayState=1')

            const scannerInfo = await firstValueFrom(this.rfidScannerService.getScannerInfo())

            if (! scannerInfo.data?.deviceToken) {
                throw new Error('No device token found in getScannerInfo')
            }

            this.unattendedCheckInService.setXDeviceToken(scannerInfo.data?.deviceToken)

            Bugsnag.addMetadata('EntranceID', {
                id: scannerInfo.data?.deviceToken,
            })

            await this.pollRfidScanner()
        } catch (error: any) {
            Bugsnag.notify({
                name: 'GetScannerInfo',
                message: error.message || '',
            })
            console.error(error)
        }
    }

    public async pollRfidScanner(): Promise<void> {
        try {
            const scanResult = await firstValueFrom(this.rfidScannerService.manualScan(60))
            if (! scanResult.success) {
                await this.pollRfidScanner()
            } else {
                await this.startFaceScanProcess()
            }
        } catch (error) {
            console.error(error)
        }
    }

    public async startFaceScanProcess(rfidCode?: string) {
        try {
            const rfidPass = await firstValueFrom(this.rfidScannerService.getData())

            if (! rfidPass.data?.code) {
                if (! rfidCode) {
                    throw new Error('No code found on RFID')
                }
            }

            const response = await this.unattendedCheckInService.startUnattendedCheckIn({
                code: rfidCode ? rfidCode : rfidPass.data!.code,
            })

            if (! response || response.visitor.visitorIdentifications.length <= 0) {
                return
            }

            let preferedDocument = response.visitor.visitorIdentifications.find((document) => document.isPreferred)

            if (! preferedDocument) {
                throw new Error('No preferred visitor identification found')
            }

            if (! preferedDocument.image) {
                throw new Error('No image found on prefered visitor identification')
            }

            let base64 = await toBase64(preferedDocument.image)
            await this.faceMatchService.setReferenceImage(base64 as string)

            await this.router.navigate(['/intro'])
        } catch (error) {
            console.error(error)
        }
    }

    public async simulateCard(card: 'a' | 'b' | 'c'): Promise<void> {
        let rfidCode
        switch (card) {
            case 'a':
                rfidCode = '40-CA-EC-03@3B-8F-80-01-80-4F-0C-A0-00-00-03-06-03-00-03-00-00-00-00-68'
                break
            case 'b':
                rfidCode = '30-56-95-03@3B-8F-80-01-80-4F-0C-A0-00-00-03-06-03-00-03-00-00-00-00-68'
                break
            case 'c':
                rfidCode = '30-C9-EC-03@3B-8F-80-01-80-4F-0C-A0-00-00-03-06-03-00-03-00-00-00-00-68'
                break
        }
        await this.startFaceScanProcess(rfidCode)
    }

    protected readonly isDevMode = isDevMode
}
