import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { MatIcon } from '@angular/material/icon'
import { TimerComponent } from '@app-ui/components/timer/timer.component'

import { HeadingDirective, ParagraphDirective } from '@app-ui/directives'
import { DefaultLayoutComponent, TimerLayoutComponent } from '@app-ui/layouts'

@Component({
    selector: 'app-success',
    standalone: true,
    imports: [
        TimerLayoutComponent,
        HeadingDirective,
        MatIcon,
        DefaultLayoutComponent,
        TimerComponent,
        ParagraphDirective,
    ],
    templateUrl: './success-page.component.html',
    styleUrl: './success-page.component.scss',
})
export class SuccessPageComponent {

    constructor(
        private readonly router: Router,
    ) {
        //
    }

    public async returnToStart(): Promise<void> {
        //TODO: Open gate
        await this.router.navigate(['/'])
    }

}
